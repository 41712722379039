import React, {Fragment} from 'react'
import {Link} from 'react-router-dom'

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import PreviewSectionTitle from './SectionTitle'

// reactjs 
import Product from '../images/demo/ecommerce/1.png';
import Shoping from '../images/demo/ecommerce/2.png';
import Wishlists from '../images/demo/ecommerce/3.png';
import Order from '../images/demo/ecommerce/4.png';
import Checkout from '../images/demo/ecommerce/5.png';

// reactjs 
import dark_Product from '../images/demo/ecommerce-dark/1.png';
import dark_Shoping from '../images/demo/ecommerce-dark/2.png';
import dark_Wishlists from '../images/demo/ecommerce-dark/3.png';
import dark_Order from '../images/demo/ecommerce-dark/4.png';
import dark_Checkout from '../images/demo/ecommerce-dark/5.png';

// reactjs 
import Rtl_Product from '../images/demo/ecommerce-rtl/1.png';
import Rtl_Shoping from '../images/demo/ecommerce-rtl/2.png';
import Rtl_Wishlists from '../images/demo/ecommerce-rtl/3.png';
import Rtl_Order from '../images/demo/ecommerce-rtl/4.png';
import Rtl_Checkout from '../images/demo/ecommerce-rtl/5.png';

// reactjs 
import Rtl_dark_Product from '../images/demo/ecommerce-dark-rtl/1.png';
import Rtl_dark_Shoping from '../images/demo/ecommerce-dark-rtl/2.png';
import Rtl_dark_Wishlists from '../images/demo/ecommerce-dark-rtl/3.png';
import Rtl_dark_Order from '../images/demo/ecommerce-dark-rtl/4.png';
import Rtl_dark_Checkout from '../images/demo/ecommerce-dark-rtl/5.png';


const demos = [
  {name: 'Product', image: Product, link: 'Product'},
  {name: 'Shopping Cart', image: Shoping, link: 'cart'},
  {name: 'Wishlists', image: Wishlists, link: 'wishlist'},
  {name: 'Order', image: Order, link: 'orders'},
  {name: 'Checkout', image: Checkout, link: 'checkout'},
];

const demos2 = [
  {name: 'Product', image: dark_Product, link: 'Product'},
  {name: 'Shopping Cart', image: dark_Shoping, link: 'cart'},
  {name: 'Wishlists', image: dark_Wishlists, link: 'wishlist'},
  {name: 'Order', image: dark_Order, link: 'orders'},
  {name: 'Checkout', image: dark_Checkout, link: 'checkout'},
];

const demos3 = [
  {name: 'Product', image: Rtl_Product, link: 'Product'},
  {name: 'Shopping Cart', image: Rtl_Shoping, link: 'cart'},
  {name: 'Wishlists', image: Rtl_Wishlists, link: 'wishlist'},
  {name: 'Order', image: Rtl_Order, link: 'orders'},
  {name: 'Checkout', image: Rtl_Checkout, link: 'checkout'},
];

const demos4 = [
  {name: 'Product', image: Rtl_dark_Product, link: 'Product'},
  {name: 'Shopping Cart', image: Rtl_dark_Shoping, link: 'cart'},
  {name: 'Wishlists', image: Rtl_dark_Wishlists, link: 'wishlist'},
  {name: 'Order', image: Rtl_dark_Order, link: 'orders'},
  {name: 'Checkout', image: Rtl_dark_Checkout, link: 'checkout'},
];

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ECommerceSec = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Fragment>
      <div className="previewDemoArea eCommerce-area" id="eCommerce">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <PreviewSectionTitle
                title="Ecommerce"
              />
            </div>
              <Tabs className="demoTabMenu" centered value={value} onChange={handleChange}
                    aria-label="simple tabs example">
              <Tab disableRipple label="Light" {...a11yProps(0)} />
              <Tab disableRipple label="Dark" {...a11yProps(1)} />
              <Tab disableRipple label="Light RTL" {...a11yProps(2)} />
              <Tab disableRipple label="Dark RTL" {...a11yProps(3)} />
            </Tabs>
            <div className="col-12">
              <TabPanel value={value} index={0}>
                <div className="row">
                  {demos.map((demo, i) => (
                    <div key={i} className="col-sm-6 col-12">
                      <div className="demoWrapper">
                      <a className="demoInfo" target="_blank"
                           href={`https://akavo.itech-theme.com/${demo.link}`}>{demo.name}</a>
                        <div className="demoImg">
                          {demo.new && <span> {demo.new} </span>}
                          <img src={demo.image} alt=""/>
                        </div>
                        <div className="demoContent">
                          <h3>{demo.name}</h3>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </TabPanel>
            </div>
            <div className="col-12">
              <TabPanel value={value} index={1}>
                <div className="row">
                  {demos2.map((demo, i) => (
                    <div key={i} className="col-sm-6 col-12">
                      <div className="demoWrapper">
                      <a className="demoInfo" target="_blank"
                           href={`https://akavo-dark.itech-theme.com/${demo.link}`}>{demo.name}</a>
                        <div className="demoImg">
                          {demo.new && <span> {demo.new} </span>}
                          <img src={demo.image} alt=""/>
                        </div>
                        <div className="demoContent">
                          <h3>{demo.name}</h3>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </TabPanel>
            </div>
            <div className="col-12">
              <TabPanel value={value} index={2}>
                <div className="row">
                  {demos3.map((demo, i) => (
                    <div key={i} className="col-sm-6 col-12">
                      <div className="demoWrapper">
                      <a className="demoInfo" target="_blank"
                           href={`https://akavo-rtl.itech-theme.com/${demo.link}`}>{demo.name}</a>
                        <div className="demoImg">
                          {demo.new && <span> {demo.new} </span>}
                          <img src={demo.image} alt=""/>
                        </div>
                        <div className="demoContent">
                          <h3>{demo.name}</h3>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </TabPanel>
            </div>
            <div className="col-12">
              <TabPanel value={value} index={3}>
                <div className="row">
                  {demos4.map((demo, i) => (
                    <div key={i} className="col-sm-6 col-12">
                      <div className="demoWrapper">
                      <a className="demoInfo" target="_blank"
                           href={`https://akavo-rtl-dark.itech-theme.com/${demo.link}`}>{demo.name}</a>
                        <div className="demoImg">
                          {demo.new && <span> {demo.new} </span>}
                          <img src={demo.image} alt=""/>
                        </div>
                        <div className="demoContent">
                          <h3>{demo.name}</h3>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </TabPanel>
            </div>

            
          </div>
        </div>
      </div>
    </Fragment>
  )
};
export default ECommerceSec;
