import React from 'react';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import DemoPreview from '../DemoPreview'

const AllRoute = () => { 

  return (
    <div className="App">
       <Router>
          <Switch>
            <Route exact path='/' component={DemoPreview} />
          </Switch>
      </Router>
      
    </div>
  );
}

export default AllRoute;
