import React, {Fragment} from 'react'
import {Link} from 'react-router-dom'

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import PreviewSectionTitle from './SectionTitle'

// reactjs 
import dashboard from '../images/demo/dashboard.jpg';
import cryptocurrency from '../images/demo/cryptocurrency.jpg';
import banking_system from '../images/demo/banking-system.jpg';
import EventManagement from '../images/demo/event-management.png';
import crm from '../images/demo/crm.jpg';
import ecommerce from '../images/demo/ecommerce.jpg';
import Medical from '../images/demo/medical.png';
import Analytical from '../images/demo/analytical.png';
import Fitness from '../images/demo/fitness.png';
import forums from '../images/demo/forums.jpg';
import contact_list from '../images/demo/contact-list.jpg';
import scrumbord from '../images/demo/scrumbord.jpg';
import todo from '../images/demo/todo.jpg';
import chat from '../images/demo/chat.jpg';
import invoice_lists from '../images/demo/invoice-lists.jpg';
import inbox from '../images/demo/inbox.jpg';
import event_calendar from '../images/demo/event-calendar.jpg';
import login from '../images/demo/login.jpg';

// reactjs 
import dark_dashboard from '../images/demo/dark/1.png';
import dark_cryptocurrency from '../images/demo/dark/2.png';
import dark_banking_system from '../images/demo/dark/3.png';
import dark_EventManagement from '../images/demo/dark/event-management.png';
import dark_crm from '../images/demo/dark/4.png';
import dark_ecommerce from '../images/demo/dark/5.png';
import dark_Medical from '../images/demo/dark/medical.png';
import dark_Analytical from '../images/demo/dark/analytical.png';
import dark_Fitness from '../images/demo/dark/fitness.png';
import dark_forums from '../images/demo/dark/6.png';
import dark_contact_list from '../images/demo/dark/7.png';
import dark_scrumbord from '../images/demo/dark/8.png';
import dark_todo from '../images/demo/dark/9.png';
import dark_chat from '../images/demo/dark/10.png';
import dark_invoice_lists from '../images/demo/dark/11.png';
import dark_inbox from '../images/demo/dark/12.png';
import dark_event_calendar from '../images/demo/dark/13.png';


// reactjs 
import LTRdashboard from '../images/demo/lRTL/1.png';
import LTRcryptocurrency from '../images/demo/lRTL/2.png';
import LTRbanking_system from '../images/demo/lRTL/3.png';
import LTR_EventManagement from '../images/demo/lRTL/event-management.png';
import LTRcrm from '../images/demo/lRTL/4.png';
import LTRecommerce from '../images/demo/lRTL/5.png';
import LTRMedical from '../images/demo/lRTL/6.png';
import LTR_Analytical from '../images/demo/lRTL/analytical.png';
import LTRFitness from '../images/demo/lRTL/fitness.png';
import LTRforums from '../images/demo/lRTL/7.png';
import LTRcontact_list from '../images/demo/lRTL/8.png';
import LTRscrumbord from '../images/demo/lRTL/9.png';
import LTRtodo from '../images/demo/lRTL/10.png';
import LTRchat from '../images/demo/lRTL/11.png';
import LTRinvoice_lists from '../images/demo/lRTL/12.png';
import LTRinbox from '../images/demo/lRTL/13.png';
import LTRevent_calendar from '../images/demo/lRTL/15.png';
import LTRlogin from '../images/demo/lRTL/14.png';


// reactjs 
import RTLdark_dashboard from '../images/demo/dark/RTL/1.png';
import RTLdark_cryptocurrency from '../images/demo/dark/RTL/2.png';
import RTLdark_banking_system from '../images/demo/dark/RTL/3.png';
import RTL_EventManagement from '../images/demo/dark/RTL/event-management.png';
import RTLdark_crm from '../images/demo/dark/RTL/4.png';
import RTLdark_ecommerce from '../images/demo/dark/RTL/5.png';
import RTLdark_Medical from '../images/demo/dark/RTL/6.png';
import RTL_Analytical from '../images/demo/dark/RTL/analytical.png';
import RTLdark_Fitness from '../images/demo/dark/RTL/fitness.png';
import RTLdark_forums from '../images/demo/dark/RTL/7.png';
import RTLdark_contact_list from '../images/demo/dark/RTL/8.png';
import RTLdark_scrumbord from '../images/demo/dark/RTL/9.png';
import RTLdark_todo from '../images/demo/dark/RTL/10.png';
import RTLdark_chat from '../images/demo/dark/RTL/11.png';
import RTLdark_invoice_lists from '../images/demo/dark/RTL/12.png';
import RTLdark_inbox from '../images/demo/dark/RTL/13.png';
import RTLdark_event_calendar from '../images/demo/dark/RTL/14.png';

// html
import html_dashboard from '../images/demo/html/img1.png';
import html_cryptocurrency from '../images/demo/html/img2.png';
import html_banking_system from '../images/demo/html/img5.png';
import html_crm from '../images/demo/html/img3.png';
import html_ecommerce from '../images/demo/html/img4.png';
import html_forums from '../images/demo/html/img6.png';
import html_contact_list from '../images/demo/html/img7.png';
import html_scrumbord from '../images/demo/html/img8.png';
import html_todo from '../images/demo/html/img9.png';
import html_chat from '../images/demo/html/img10.png';
import html_invoice_lists from '../images/demo/html/img11.png';
import html_inbox from '../images/demo/html/img12.png';
import html_event_calendar from '../images/demo/html/img13.png';

// html
import html_dark_dashboard from '../images/demo/dark/1.png';
import html_dark_cryptocurrency from '../images/demo/dark/2.png';
import html_dark_banking_system from '../images/demo/dark/3.png';
import html_dark_crm from '../images/demo/dark/4.png';
import html_dark_ecommerce from '../images/demo/dark/5.png';
import html_dark_forums from '../images/demo/dark/6.png';
import html_dark_contact_list from '../images/demo/dark/7.png';
import html_dark_scrumbord from '../images/demo/dark/8.png';
import html_dark_todo from '../images/demo/dark/9.png';
import html_dark_chat from '../images/demo/dark/10.png';
import html_dark_invoice_lists from '../images/demo/dark/11.png';
import html_dark_inbox from '../images/demo/dark/12.png';
import html_dark_event_calendar from '../images/demo/dark/13.png';

// reactjs 
import Lrbdashboard from '../images/demo/dashboard.jpg';
import Lrbcryptocurrency from '../images/demo/cryptocurrency.jpg';
import Lrbbanking_system from '../images/demo/banking-system.jpg';
import Lrbcrm from '../images/demo/crm.jpg';
import Lrbecommerce from '../images/demo/ecommerce.jpg';


const demos = [
  {name: 'dashboard', image: dashboard, link: 'dashboard'},
  {name: 'cryptocurrency', image: cryptocurrency, link: 'cryptocurrency'},
  {name: 'banking system', image: banking_system, link: 'banking-system'},
  {name: 'Event Management', image: EventManagement, link: 'event-management'},
  {name: 'crm', image: crm, link: 'crm'},
  {name: 'ecommerce', image: ecommerce, link: 'ecommerce'},
  {name: 'Medical', image: Medical, link: 'medical'},
  {name: 'Analytical', image: Analytical, link: 'analytical'},
  {name: 'Fitness', image: Fitness, link: 'fitness'},
  {name: 'forums', image: forums, link: 'forums'},
  {name: 'contact list', image: contact_list, link: 'contact-list'},
  {name: 'scrumbord', image: scrumbord, link: 'scrumbord'},
  {name: 'todo', image: todo, link: 'todo'},
  {name: 'chat', image: chat, link: 'chat'},
  {name: 'invoice lists', image: invoice_lists, link: 'invoice-lists'},
  {name: 'inbox', image: inbox, link: 'inbox'},
  {name: 'event calendar', image: event_calendar, link: 'event-calendar'},
  {name: 'login', image: login, link: 'login'},
];

const demos2 = [
  {name: 'dashboard', image: dark_dashboard, link: 'dashboard'},
  {name: 'cryptocurrency', image: dark_cryptocurrency, link: 'cryptocurrency'},
  {name: 'banking system', image: dark_banking_system, link: 'banking-system'},
  {name: 'Event Management', image: dark_EventManagement, link: 'event-management'},
  {name: 'crm', image: dark_crm, link: 'crm'},
  {name: 'ecommerce', image: dark_ecommerce, link: 'ecommerce'},
  {name: 'Medical', image: dark_Medical, link: 'medical'},
  {name: 'Analytical', image: dark_Analytical, link: 'analytical'},
  {name: 'Fitness', image: dark_Fitness, link: 'fitness'},
  {name: 'forums', image: dark_forums, link: 'forums'},
  {name: 'contact list', image: dark_contact_list, link: 'contact-list'},
  {name: 'scrumbord', image: dark_scrumbord, link: 'scrumbord'},
  {name: 'todo', image: dark_todo, link: 'todo'},
  {name: 'chat', image: dark_chat, link: 'chat'},
  {name: 'invoice lists', image:dark_invoice_lists, link: 'invoice-lists'},
  {name: 'inbox', image: dark_inbox, link: 'inbox'},
  {name: 'event calendar', image: dark_event_calendar, link: 'event-calendar'},
];

const demos3 = [
  {name: 'dashboard', image: LTRdashboard, link: 'dashboard'},
  {name: 'cryptocurrency', image: LTRcryptocurrency, link: 'cryptocurrency'},
  {name: 'banking system', image: LTRbanking_system, link: 'banking-system'},
  {name: 'Event Management', image: LTR_EventManagement, link: 'event-management'},
  {name: 'crm', image: LTRcrm, link: 'crm'},
  {name: 'ecommerce', image: LTRecommerce, link: 'ecommerce'},
  {name: 'Medical', image: LTRMedical, link: 'medical'},
  {name: 'Analytical', image: LTR_Analytical, link: 'analytical'},
  {name: 'Fitness', image: LTRFitness, link: 'fitness'},
  {name: 'forums', image: LTRforums, link: 'forums'},
  {name: 'contact list', image: LTRcontact_list, link: 'contact-list'},
  {name: 'scrumbord', image: LTRscrumbord, link: 'scrumbord'},
  {name: 'todo', image: LTRtodo, link: 'todo'},
  {name: 'chat', image: LTRchat, link: 'chat'},
  {name: 'invoice lists', image: LTRinvoice_lists, link: 'invoice-lists'},
  {name: 'inbox', image: LTRinbox, link: 'inbox'},
  {name: 'event calendar', image: LTRevent_calendar, link: 'event-calendar'},
  {name: 'login', image: LTRlogin, link: 'login'},
];



const demos4 = [
  {name: 'dashboard', image: RTLdark_dashboard, link: 'dashboard'},
  {name: 'cryptocurrency', image: RTLdark_cryptocurrency, link: 'cryptocurrency'},
  {name: 'banking system', image: RTLdark_banking_system, link: 'banking-system'},
  {name: 'Event Management', image: RTL_EventManagement, link: 'event-management'},
  {name: 'crm', image: RTLdark_crm, link: 'crm'},
  {name: 'ecommerce', image: RTLdark_ecommerce, link: 'ecommerce'},
  {name: 'Medical', image: RTLdark_Medical, link: 'medical'},
  {name: 'Analytical', image: RTL_Analytical, link: 'analytical'},
  {name: 'Fitness', image: RTLdark_Fitness, link: 'fitness'},
  {name: 'forums', image: RTLdark_forums, link: 'forums'},
  {name: 'contact list', image: RTLdark_contact_list, link: 'contact-list'},
  {name: 'scrumbord', image: RTLdark_scrumbord, link: 'scrumbord'},
  {name: 'todo', image: RTLdark_todo, link: 'todo'},
  {name: 'chat', image: RTLdark_chat, link: 'chat'},
  {name: 'invoice lists', image:RTLdark_invoice_lists, link: 'invoice-lists'},
  {name: 'inbox', image: RTLdark_inbox, link: 'inbox'},
  {name: 'event calendar', image: RTLdark_event_calendar, link: 'event-calendar'},
];


const demos5 = [
  {name: 'dashboard', image: html_dashboard, link: 'index.html'},
  {name: 'cryptocurrency', image: html_cryptocurrency, link: 'cryptocurrency.html'},
  {name: 'banking system', image: html_banking_system, link: 'banking-system.html'},
  {name: 'crm', image: html_crm, link: 'crm.html'},
  {name: 'ecommerce', image: html_ecommerce, link: 'ecommerce.html'},
  {name: 'forums', image: html_forums, link: 'forums.html'},
  {name: 'contact list', image: html_contact_list, link: 'contact-list.html'},
  {name: 'scrumbord', image: html_scrumbord, link: 'scrumbord.html'},
  {name: 'todo', image: html_todo, link: 'todos.html'},
  {name: 'chat', image: html_chat, link: 'chat.html'},
  {name: 'invoice lists', image: html_invoice_lists, link: 'invoice-lists.html'},
  {name: 'inbox', image: html_inbox, link: 'inbox.html'},
  {name: 'event calendar', image: html_event_calendar, link: 'event-calendar.html'},
];
const demos6 = [
  {name: 'dashboard', image: html_dark_dashboard, link: 'index.html'},
  {name: 'cryptocurrency', image: html_dark_cryptocurrency, link: 'cryptocurrency.html'},
  {name: 'banking system', image: html_dark_banking_system, link: 'banking-system.html'},
  {name: 'crm', image: html_dark_crm, link: 'crm.html'},
  {name: 'ecommerce', image: html_dark_ecommerce, link: 'ecommerce.html'},
  {name: 'forums', image: html_dark_forums, link: 'forums.html'},
  {name: 'contact list', image: html_dark_contact_list, link: 'contact-list.html'},
  {name: 'scrumbord', image: html_dark_scrumbord, link: 'scrumbord.html'},
  {name: 'todo', image: html_dark_todo, link: 'todos.html'},
  {name: 'chat', image: html_dark_chat, link: 'chat.html'},
  {name: 'invoice lists', image: html_dark_invoice_lists, link: 'invoice-lists.html'},
  {name: 'inbox', image: html_dark_inbox, link: 'inbox.html'},
  {name: 'event calendar', image: html_dark_event_calendar, link: 'event-calendar.html'},
];

const demos7 = [
  {name: 'dashboard', image: Lrbdashboard, link: 'dashboard'},
  {name: 'cryptocurrency', image: Lrbcryptocurrency, link: 'cryptocurrency'},
  {name: 'banking system', image: Lrbbanking_system, link: 'bankingSystem'},
  {name: 'crm', image: Lrbcrm, link: 'crm'},
  {name: 'ecommerce', image: Lrbecommerce, link: 'ecommerce'},
];

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const PreviewDemo = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Fragment>
      <div className="previewDemoArea" id="demo">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <PreviewSectionTitle
                title="Demo"
                text="There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form."
              />
            </div>
            <Tabs className="demoTabMenu" centered value={value} onChange={handleChange}
                  aria-label="simple tabs example">
              <Tab disableRipple label="Reactjs" {...a11yProps(0)} />
              <Tab disableRipple label="Reactjs Dark" {...a11yProps(1)} />
              <Tab disableRipple label="Reactjs Light RTL" {...a11yProps(2)} />
              <Tab disableRipple label="Reactjs Dark RTL" {...a11yProps(3)} />
              <Tab disableRipple label="Html version" {...a11yProps(4)} />
              <Tab disableRipple label="Html Dark version" {...a11yProps(5)} />
              <Tab disableRipple label="Laravel version" {...a11yProps(6)} />
            </Tabs>
            <div className="col-12">
              <TabPanel value={value} index={0}>
                <div className="row">
                  {demos.map((demo, i) => (
                    <div key={i} className="col-sm-6 col-12">
                      <div className="demoWrapper">
                      <a className="demoInfo" target="_blank"
                           href={`https://akavo.itech-theme.com/${demo.link}`}>{demo.name}</a>
                        <div className="demoImg">
                          {demo.new && <span> {demo.new} </span>}
                          <img src={demo.image} alt=""/>
                        </div>
                        <div className="demoContent">
                          <h3>{demo.name}</h3>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </TabPanel>
            </div>
            <div className="col-12">
              <TabPanel value={value} index={1}>
                <div className="row">
                  {demos2.map((demo, i) => (
                    <div key={i} className="col-sm-6 col-12">
                      <div className="demoWrapper">
                        <a className="demoInfo" target="_blank"
                           href={`https://akavo-dark.itech-theme.com/${demo.link}`}>{demo.name}</a>
                        <div className="demoImg">
                          {demo.new && <span> {demo.new} </span>}
                          <img src={demo.image} alt=""/>
                        </div>
                        <div className="demoContent">
                          <h3>{demo.name}</h3>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </TabPanel>
            </div>
            <div className="col-12">
              <TabPanel value={value} index={2}>
                <div className="row">
                  {demos3.map((demo, i) => (
                    <div key={i} className="col-sm-6 col-12">
                      <div className="demoWrapper">
                        <a className="demoInfo" target="_blank"
                           href={`https://akavo-rtl.itech-theme.com/${demo.link}`}>{demo.name}</a>
                        <div className="demoImg">
                          {demo.new && <span> {demo.new} </span>}
                          <img src={demo.image} alt=""/>
                        </div>
                        <div className="demoContent">
                          <h3>{demo.name}</h3>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </TabPanel>
            </div>
            <div className="col-12">
              <TabPanel value={value} index={3}>
                <div className="row">
                  {demos4.map((demo, i) => (
                    <div key={i} className="col-sm-6 col-12">
                      <div className="demoWrapper">
                        <a className="demoInfo" target="_blank"
                           href={`https://akavo-rtl-dark.itech-theme.com/${demo.link}`}>{demo.name}</a>
                        <div className="demoImg">
                          {demo.new && <span> {demo.new} </span>}
                          <img src={demo.image} alt=""/>
                        </div>
                        <div className="demoContent">
                          <h3>{demo.name}</h3>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </TabPanel>
            </div>
            <div className="col-12">
              <TabPanel value={value} index={4}>
                <div className="row">
                  {demos5.map((demo, i) => (
                    <div key={i} className="col-sm-6 col-12">
                      <div className="demoWrapper">
                        <a className="demoInfo" target="_blank"
                           href={`https://akavo-html.itech-theme.com/${demo.link}`}>{demo.name}</a>
                        <div className="demoImg">
                          {demo.new && <span> {demo.new} </span>}
                          <img src={demo.image} alt=""/>
                        </div>
                        <div className="demoContent">
                          <h3>{demo.name}</h3>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </TabPanel>
            </div>
            <div className="col-12">
              <TabPanel value={value} index={5}>
                <div className="row">
                  {demos6.map((demo, i) => (
                    <div key={i} className="col-sm-6 col-12">
                      <div className="demoWrapper">
                        <a className="demoInfo" target="_blank"
                           href={`https://akavo-dark-html.itech-theme.com/${demo.link}`}>{demo.name}</a>
                        <div className="demoImg">
                          {demo.new && <span> {demo.new} </span>}
                          <img src={demo.image} alt=""/>
                        </div>
                        <div className="demoContent">
                          <h3>{demo.name}</h3>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </TabPanel>
            </div>
            <div className="col-12">
              <TabPanel value={value} index={6}>
                <div className="row">
                  {demos7.map((demo, i) => (
                    <div key={i} className="col-sm-6 col-12">
                      <div className="demoWrapper">
                        <a className="demoInfo" target="_blank"
                           href={`https://akavo-laravel.itech-theme.com/${demo.link}`}>{demo.name}</a>
                        <div className="demoImg">
                          {demo.new && <span> {demo.new} </span>}
                          <img src={demo.image} alt=""/>
                        </div>
                        <div className="demoContent">
                          <h3>{demo.name}</h3>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </TabPanel>
            </div>
            
          </div>
        </div>
      </div>
    </Fragment>
  )
};
export default PreviewDemo
